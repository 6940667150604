body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #3E5151;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #DECBA4, #3E5151);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #DECBA4, #3E5151); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login__form{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.input{
  height: 30px;
  width: 130px;
  margin:10px;
  border-radius: 10px;
  border-style: none;
  padding: 5px;
  font-weight: 700;
}

.button{
  height: 40px;
  border-style:none ;
  border-radius: 10px;
  margin-top: 2%;
  cursor: pointer;

}

.button:hover{
  background-color: beige;
}


.section__fabrics{
 margin-top: 5%;
width: 80%;
  border: 1px solid black;

}



.section__fabrics ul li {
  display: flex;
  align-items: center;
  justify-content:space-evenly;


}

.section__fabrics ul {
  padding: 0;
  list-style: none;
}
.search__box{

  list-style: none;
  margin-top: 20px;
  font-size: 30px;
  color: red;
}
.search__box span{
  color:black;
  padding: 20px;
}

.search__box li {
  display: flex;
  flex-direction: row;

}

@media (max-width: 480px ) {


  .section__fabrics ul li {

  flex-direction: column;

  }

.search__box li {
  flex-direction: column;
}

 
}